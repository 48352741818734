import React, { useState } from 'react';

import MothyGrayLogo from "../images/mothy_gray.svg"

function HeroHome() {
  return (
    <section className="relative">
      <div className="max-w-6xl mx-auto px-4 sm:px-6">

        {/* Hero content */}
        <div className="pt-32 pb-12 md:pt-40 md:pb-20">

          {/* Section header */}
          <div className="flex flex-col lg:flex-row items-center justify-center text-center pb-12 md:pb-16">
            <img src={MothyGrayLogo} data-aos="zoom-y-out"></img>
            <hr className="border border-gray-800 w-60 my-10 lg:h-60 lg:mx-10 lg:w-0 lg:my-0"></hr>
            <div className="flex flex-col">
              <h1 className="text-4xl md:text-4xl font-extrabold leading-tighter tracking-tighter mb-4 text-gray-200" data-aos="zoom-y-out">Coming Soon</h1>
              <div className="text-gray-200" data-aos="zoom-y-out">Follow us on <a href="https://twitter.com/MothyBot" className="text-red-800">Twitter</a> for updates</div>
            </div>
          </div>
          
        </div>

      </div>
    </section>
  );
}

export default HeroHome;